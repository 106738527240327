@keyframes zoom {
  from {
    scale: 1;
  }

  to {
    scale: 1.2;
  }
}

.Background {
  transition: opacity 2s ease-in-out, scale 5s ease-out;
}

.Background.anim {
  animation: zoom 5s ease-in-out alternate infinite;
}
